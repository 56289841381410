<template>
   <div class="component_total">
        <!-- <img src="@/assets/img_c.png" />
        <div class="text">111</div> -->
        <div class="text">
            <span>{{value}}</span>
            <div style="font-size: 14px;text-align: center;margin-left: 1rem;margin-top: 10px;">{{People}} 人评课 </div>
            
        </div>
        
   </div>
</template>

<script>
// import Imgs from '@/assets/'
export default {
    props:{
        value:Number,
        People:{
            type:Number,
            default:0
        }
    },
    data(){
        return{

        }
    },
    created(){
        this.init()
    },
    methods:{
        init(){
        
        }
    }
}
</script>

<style scoped lang="less">
.component_total {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    
    .text {
        width: 60%;
        height: 100%;
        background: url("~@/assets/img_c.png") no-repeat center;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        font-size: 3rem;
        span {
            margin-left: 1rem;
            display: block;
        }
    }

}
</style>