<template>
  <div :class="{dimension:true,close:close}">
    <div
      class="h-title"
      @click="handleClose"
      :style="'background-color:rgba('+this.$props.color+'1)'"
    >
      <div class="name">{{ name }}</div>

      <div class="scores">
        <span>总得分 </span>
        <span>{{score || 0}}分</span>

        <button>
          <img :class="{close:close}" src="../../../assets/imgs/video-feed/pc/dis-more.png" />
        </button>
      </div>
    </div>

    <div class="data-table" :style="'border-color:rgba('+this.$props.color+'0.6)'">
      <table>
        <tr>
          <th>指示值</th>
          <th>指标分值</th>
          <th>评价</th>
        </tr>

        <tr v-for="(item,index) in list" :key="index">
          <td>{{item.name}}</td>
          <td>{{item.maxScore}}</td>
          <td>
            <el-rate disabled-void-color="" :value="item.max" 
            :colors="['#E53B00','#4C79EB','#4C79EB']"
            @change="(val)=>onChange(val,item)"
            ></el-rate>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
    name: "DimensionView",
    props: {
        name:String,
        color: String,
        open:Boolean,
        list:Array,
        score:Number,
        disabled:Boolean
    },
    data() {
            return {
                close: true,
                max:0,//分数
            };
    },

    watch:{
        score(val){
            // console.log('ssss:'.val)
        }
    },

    created(){
        this.close = !this.$props.open || false
      
    },
    methods: {
        handleClose() {
            if(this.$props.disabled) return false;
            this.close = !this.close;
        },
        onChange(val,item){
            let obj = {
                val:val,
                item:item
            }
            this.$emit('changeRule',obj)
        }
    }
};
</script>

<style lang="less" scoped>
.dimension {
  border-radius: 0.21rem;
  margin-bottom: 10px;
}

.dimension.close {
  height: 32px;
  overflow: hidden;
}
.h-title {
  cursor: pointer;
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  height: 32px;
  padding-left: 1.04rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #3f85ff;
  border-radius: 0.21rem 0.21rem 0rem 0rem;
  .name {
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    max-width: 70%;
  }
  button {
    background-color: transparent;
    border: 0;
    padding: 5px;
    img {
      display: block;
      width: 10px;
    }

    img.close {
      transform: rotate(180deg);
    }
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.data-table {
    border: 0.05rem solid #6ca2ff;
    border-radius: 0rem 0rem 0.21rem 0.21rem;
    text-align: left;
        
    padding: 0.57rem 0.52rem 0.52rem 0.52rem;
    table {
        width: 100%;
        border-right: 0.05rem solid #e0e6ed;
        border-bottom: 0.05rem solid #e0e6ed;
    }
    td,
    th {
        border-top: 0.05rem solid #e0e6ed;
        border-left: 0.05rem solid #e0e6ed;
    }

    th {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        // padding: 0.52rem 0.63rem;
        padding: 10px 12px;
    }

    td {
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        padding: 0.42rem 0.68rem;
    }
    tr th:last-child,
    tr td:last-child {
        padding-left: 0.31rem;
        /deep/ .el-rate__icon {
            font-size: 15px;
            margin-right: 0.32rem;
        }
        /deep/ .el-rate__item:last-child .el-rate__icon {
            margin-right: 0;
        }
    }
}
</style>

