<template>
   <div ref="picbarchart" style="width: 100%;height: 100%;">

   </div>
</template>

<script>
import * as echarts from 'echarts';
import {mergeObjects} from '@/global/utils.js'
export default {
    props:{
        xAxisData:Array,
        seriesData:Array
    },
    data(){
        return{
            options:{
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'none'
                    },
                    formatter: function (params) {
                        return params[0].name + ': ' + params[0].value;
                    }
                },
                xAxis: {
                    data: [],
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: {
                        color: '#3F3FFF',
                        rotate:60,
                        interval:0
                    }
                },
                yAxis: {
                    splitLine: { show: false },
                    axisTick: { show: false },
                    axisLine: { show: false },
                    axisLabel: { show: false }
                },
                color: ['#3F3FFF'],
                series: [
                    // {
                    //     name: 'hill',
                    //     type: 'pictorialBar',
                    //     barCategoryGap: '-130%',
                    //     // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
                    //     symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
                    //     itemStyle: {
                    //         opacity: 0.5
                    //     },
                    //     emphasis: {
                    //         itemStyle: {
                    //             opacity: 1
                    //         }
                    //     },
                    //     data: [200,100,50,19,16,13,11,1],
                    //     z: 10
                    // }

                ]
            }
            
        }
    },
    watch:{
        seriesData(val){
            this.options.xAxis.data = this.$props.xAxisData
            this.options.series = val
            this.init()
        }
    },
    methods:{
        init(){
            var myChart = echarts.init(this.$refs.picbarchart)
            myChart.setOption(this.options)
            window.addEventListener('resize', function () {
                myChart.resize();
            });
        }
    }
}
</script>

<style scoped>

</style>