<template>
   <div ref="pic_bar" style="width: 100%;height: 100%"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    props:['data'],
    data(){
        return{
            options : {
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    // data: ['Rainfall', 'Evaporation'],
                    
                },
                grid:{
                    left:'5%',
                    right:'5%',
                    top:'15%',
                    bottom:'20%'
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        splitNumber: 5 ,// 设置 X 轴网格数量
                        axisLabel: {
                            color: '#3F3FFF',
                            rotate:60,
                            interval:0
                        }
                    // prettier-ignore
                        // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    }
                ],
                yAxis:{
                    type: 'value',
                    splitNumber:5, //网格数量
                },
                series: [
                    // {
                    //     name: 'Rainfall',
                    //     type: 'bar',
                    //     data: [
                    //         2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                    //     ],
                    //     // markPoint: {
                    //     //     data: [
                    //     //         { type: 'max', name: 'Max' },
                    //     //         { type: 'min', name: 'Min' }
                    //     //     ]
                    //     // },
                    //     // markLine: {
                    //     //     data: [{ type: 'average', name: 'Avg' }]
                    //     // }
                    // },
                    // {
                    //     name: 'Evaporation',
                    //     type: 'bar',
                    //     data: [
                    //         2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                    //     ],
                    //     // markPoint: {
                    //     //     data: [
                    //     //     { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
                    //     //     { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 }
                    //     //     ]
                    //     // },
                    //     // markLine: {
                    //     //     data: [{ type: 'average', name: 'Avg' }]
                    //     // }
                    // }
                ]}
        }
    },
    watch:{
        data(val){
            this.options.legend.data = val.names
            this.options.xAxis[0].data = val.params
            let _series = []
            val.data && val.data.forEach((item,index) => {
                let _color_obj = this.$store.state.dict.barColor
                let _color = _color_obj[index%_color_obj.length]
                _series.push({
                    name:val.names[index],
                    type:'bar',
                    data:item,
                    label:{show:true,position:'top'},
                    itemStyle:{color:_color}
                })
            });
            this.options.series = _series
            this.init()
        }
    },
    methods:{
        init(){
            let _this = this
            this.myChart = echarts.init(this.$refs.pic_bar)
            this.myChart.setOption(this.options)
            window.addEventListener('resize', function () {
                _this.myChart.resize();
            });
        }
    }
}
</script>

<style scoped>

</style>